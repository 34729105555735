const getSites = async () => {
    let result = null
    try {
        result = await window.axios.get('/v1/sites/portfolio')
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getControlPermissionBySiteId = async (siteId) => {
    let result = null
    try {
        result = await window.axios.get(`/v1/controlpermissions/site/${siteId}`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const getControlPermissionLoginTypes = async () => {
    let result = null
    try {
        result = await window.axios.get(`/v1/controlpermissions/logintypes`)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};


const deleteControlPermission = async (payload) => {
    let result = null
    let config = {
        data: payload
    }
    try {
        result = await window.axios.delete('/v1/controlpermissions/delete', config)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const createControlPermission = async (permission) => {
    try {
        await window.axios.post('/v1/controlpermissions/create', permission)
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const updateControlPermission = async (permission) => {
    let result = null;
    try {
      result = await window.axios.put('/v1/controlpermissions/update', permission);
      return result.data.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }
  

const createLogin = async (login) => {
    try {
        await window.axios.post('/v1/controlpermissions/logins/create', login)
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const deleteLogin = async (login, controlPermissionId) => {
    let result = null

    let config = {
        data: {loginId: login.id, controlPermissionId: controlPermissionId}
    }

    try {
        result = await window.axios.delete('/v1/controlpermissions/logins/delete', config)
        let list = result.data.data;
        return list;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

const updateLogin = async (login) => {
    let result = null;
    try {
      result = await window.axios.put('/v1/controlpermissions/logins/update', login);
      return result.data.data;
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }
  

export default {
    getSites,
    getControlPermissionBySiteId,
    getControlPermissionLoginTypes,
    createControlPermission,
    deleteControlPermission,
    updateControlPermission,
    createLogin,
    deleteLogin,
    updateLogin
}
