<template>
  <v-container grid-list-xl>
    <p class="headline pt-3">Create Control Permission</p>
    <validation-observer ref="obs" v-slot="{ invalid }">
      <v-form @submit.prevent="submit" v-model="valid">
        <v-sheet elevation="2" class="pa-4">
          <v-row>
            <v-col sm="6">
              <validated-text-field
                v-model="currentAccount"
                label="Account String"
                hint="account name string used for api url"
                persistent-hint
                rules="required"
                ref="nameText"
              ></validated-text-field
            ></v-col>
            <v-col sm="6">
              <v-checkbox
                v-model="currentPermission.isIgnored"
                color="primary"
                label="Ignore"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn type="submit" color="primary" :disabled="invalid"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-sheet>
      </v-form></validation-observer
    >
  </v-container>
</template>
<style>
.card-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 25vh;
  width: 50%;
  margin-left: 25%;
}
</style>
<script>
import { ValidationObserver } from "vee-validate";
import api from "../_api";
import ValidatedTextField from "@/components/Fields/ValidatedTextField";

export default {
  components: {
    "validated-text-field": ValidatedTextField,
    "validation-observer": ValidationObserver,
  },

  data() {
    return {
      valid: true,
      currentPermission: {},
      currentAccount: null,
      friendlyName: null,
      isValidating: false,
      vSteps: 0,
      isValidPermission: true,
      isValidPermissionName: false,
      isValidFriendlyName: false,
      isValidLocation: false,
      dialog: false,
      siteId: null,
      loginTypes: [],
    };
  },

  async created() {
    try {
      this.siteId = this.$route.params.siteId;
    } catch (error) {
      console.log(error);
    }
  },

  computed: {},

  methods: {
    async submit() {
      let valid = await this.$refs.obs.validate();
      if (valid === false) {
        return;
      }

      await this.createPermission();
    },

    async handleGetControlPermissions() {
      this.currentPermission = await api.getControlPermissionBySiteId(
        this.siteId
      );
    },

    async createPermission() {
     
      this.loading = true;
      let permission = {
        siteId: this.siteId,
        account: this.currentAccount,
        isIgnored: this.currentPermission.isIgnored
      };
      try {
        let newIndex = await api.createControlPermission(permission);
        console.log(newIndex);
        this.$router.push({ name: "ControlPermissionsIndex" });
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
      // }
    },
  },
};
</script>